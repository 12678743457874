<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-tabs
                class="classify-tabs"
                v-model="queryFormModel.approvalStatus"
                type="card"
                @tab-click="onQuery()"
            >
                <el-tab-pane
                    label="待审核"
                    name="10"
                />
                <el-tab-pane
                    label="审核通过"
                    name="100"
                />
                <el-tab-pane
                    label="审核不通过"
                    name="99"
                />
            </el-tabs>
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="orderSn"
                    label="订单编号"
                >
                    <el-input
                        v-model="queryFormModel.orderSn"
                        placeholder="请输入订单编号"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="是否可用"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        placeholder="请选择"
                    >
                        <el-option
                            key="1"
                            label="是"
                            value="1"
                        />
                        <el-option
                            key="2"
                            label="否"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        size="small"
                        @click="onConfirmPass"
                        v-if="queryFormModel.approvalStatus == 10"
                    >
                        审核通过
                    </el-button>
                    <el-button
                        type="success"
                        size="small"
                        @click="onConfirmErr"
                        v-if="queryFormModel.approvalStatus == 10"
                    >
                        审核不通过
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="orderSn"
                    label="订单编号"
                    min-width="100"
                />
                <el-table-column
                    prop="goodsName"
                    label="商品名称"
                    min-width="100"
                />
                <el-table-column
                    prop="specDesc"
                    label="规格名称"
                    min-width="100"
                />
                <el-table-column
                    prop="rating"
                    label="评价等级"
                    width="190"
                >
                    <template slot-scope="scope">
                        <el-rate
                            v-model="scope.row.rating"
                            disabled
                            show-text
                            :texts="['非常差','差','一般','满意','超赞']"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    prop="reviewContent"
                    label="评价内容"
                    min-width="100"
                />
                <el-table-column
                    label="评价图片"
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <ImageList
                            :data="scope.row.reviewAttachmentList[0]"
                        />
                    </template>
                </el-table-column>
                <el-table-column
                    label="评价时间"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.reviewTime | moment('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="是否可用"
                    width="100"
                >
                    <template slot-scope="scope">
                        {{ scope.row.isAvailable ? '是' : '否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="70"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="$router.push('/order/goodsReview/goodsReview/detail/' + scope.row.id)"
                        >
                            {{ scope.row.approvalStatus === 10 ? '审核' : '查看' }}
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixins';

export default {
    name: 'GoodsReview',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                goodsName: '', // 商品名称
                orderSn: '', // 订单编号
                isAvailable: '',
                approvalStatus: '10',
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Ex.GoodsReview.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onConfirmPass() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length <= 0) {
                this.$message({
                    message: '请选择数据',
                    type: 'error',
                });
                return;
            }
            this.$confirm('是否确定批量审核通过！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.GoodsReview.batchApproval({ ids: ids.join(','), approvalStatus: 100 }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onConfirmErr() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length <= 0) {
                this.$message({
                    message: '请选择数据',
                    type: 'error',
                });
                return;
            }
            this.$confirm('是否确定批量审核不通过！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Ex.GoodsReview.batchApproval({ ids: ids.join(','), approvalStatus: 99 }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
