<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>评价信息</span>
                    </div>
                    <div class="item">
                        <el-col :span="12">
                            <el-form-item
                                label="订单编号:"
                                label-width="8em"
                            >
                                {{ goodsReview.orderSn }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="商品名称:"
                                label-width="8em"
                            >
                                {{ goodsReview.goodsName }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="规格名称:"
                                label-width="8em"
                            >
                                {{ goodsReview.specDesc }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="商品图片:"
                                label-width="8em"
                            >
                                <ImageList
                                    :data="goodsReview.specImageUrl"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="评价等级:"
                                label-width="8em"
                            >
                                <el-rate
                                    v-model="goodsReview.rating"
                                    show-text
                                    :texts="['非常差','差','一般','满意','超赞']"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="评价内容:"
                                label-width="8em"
                            >
                                <el-input
                                    type="textarea"
                                    v-model="goodsReview.reviewContent"
                                    auto-complete="off"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="评价图片:"
                                label-width="8em"
                            >
                                <upload
                                    validate-md5
                                    action="/rs/attachment/uploadFileLibrary"
                                    :limit="10"
                                    v-model="goodsReview.reviewAttachment"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label="是否可用:"
                                label-width="8em"
                            >
                                <template>
                                    <el-radio
                                        v-model="goodsReview.isAvailable"
                                        :label="true"
                                    >
                                        是
                                    </el-radio>
                                    <el-radio
                                        v-model="goodsReview.isAvailable"
                                        :label="false"
                                    >
                                        否
                                    </el-radio>
                                </template>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="评价时间:"
                                label-width="8em"
                            >
                                {{ goodsReview.reviewTime | moment('YYYY-MM-DD HH:mm:ss') }}
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item
                                label-width="8em"
                                class="ma-t"
                            >
                                <el-button
                                    type="primary"
                                    size="small"
                                    @click="onConfirm()"
                                    v-if="goodsReview.approvalStatus != 10"
                                >
                                    保存
                                </el-button>
                                <el-button
                                    type="primary"
                                    size="small"
                                    @click="onConfirmPass()"
                                    v-if="goodsReview.approvalStatus == 10"
                                >
                                    审核通过
                                </el-button>
                                <el-button
                                    type="primary"
                                    size="small"
                                    @click="onConfirmErr()"
                                    v-if="goodsReview.approvalStatus == 10"
                                >
                                    审核不通过
                                </el-button>
                                <el-button
                                    size="small"
                                    @click="$router.back()"
                                >
                                    返 回
                                </el-button>
                            </el-form-item>
                        </el-col>
                    </div>
                </el-card>
            </el-form>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'GoodsReviewDetail',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
            },
            // 表单校验规则
            formRules: {},
            goodsReview: {
                specImageUrl: '',
            },
        };
    },
    methods: {
        init() {
            this.$api.Ex.GoodsReview.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.goodsReview = res;
            });
        },
        onConfirmPass() {
            this.$confirm('是否确定审核通过！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.goodsReview.approvalStatus = 100;
                this.$api.Ex.GoodsReview.save({
                    ...this.goodsReview,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                });
            });
        },
        onConfirmErr() {
            this.$confirm('是否确定审核不通过！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.goodsReview.approvalStatus = 99;
                this.$api.Ex.GoodsReview.save({
                    ...this.goodsReview,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.init();
                });
            });
        },
        onConfirm() {
            this.$api.Ex.GoodsReview.save({
                ...this.goodsReview,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.init();
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
